<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
      <el-table-column prop="version_name" label="版本名" >
      </el-table-column>
      <el-table-column prop="version_code" label="版本号" >
      </el-table-column>
      <el-table-column prop="address" label="平台" width="78">
        <template #default="scope">
          <span v-if="scope.row.platform==0">客户端</span>
          <span v-if="scope.row.platform==1">师傅端</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="版本类型" width="78">
        <template #default="scope">
          <span v-if="scope.row.target==0">Android</span>
          <span v-if="scope.row.target==1">ios</span>
        </template>
      </el-table-column>
      <el-table-column prop="download_url" label="下载地址" >
        <template #default="scope">
          <el-popover
              placement="bottom"

              title="下载地址"
              :width="200"
              trigger="click"
              :content="scope.row.download_url">
            <template #reference>
              <el-button type="text">{{scope.row.download_url}}</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="是否可忽略" width="90">
        <template #default="scope">
          <span v-if="scope.row.is_ignorable==0">否</span>
          <span v-if="scope.row.is_ignorable==1">是</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="是否启用" width="90">
        <template #default="scope">
          <el-tag size="mini" type="info" v-if="scope.row.is_enable==0">不启用</el-tag>
          <el-tag size="mini"  v-if="scope.row.is_enable==1">启用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="描述">
      </el-table-column>
      <el-table-column width="160" prop="create_time" label="添加时间">
      </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template #header>
            <div style="text-align: center;">
            <el-button  size="mini" @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
            </div>
          </template>
          <template #default="scope">
            <div style="text-align: center;margin: 5px 0">
              <el-button-group>
<!--                              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>-->
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>

          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>

      </div>
    <el-drawer
        title="新增"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">

        <el-form-item label="版本名" prop="version_name">
          <el-input v-model="ruleForm.version_name"></el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="version_code">
          <el-input v-model="ruleForm.version_code"></el-input>
        </el-form-item>
        <el-form-item label="下载地址" prop="download_url">
          <el-input v-model="ruleForm.download_url"></el-input>
        </el-form-item>
        <el-form-item label="平台" prop="platform">
          <el-select  style="width:100%" v-model="ruleForm.platform" >
            <el-option
                v-for="item in platformArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本类型" prop="target">
          <el-select  style="width:100%" v-model="ruleForm.target" >
            <el-option
                v-for="item in modeArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="可否忽略" prop="is_ignorable">
          <el-select  style="width:100%" v-model="ruleForm.is_ignorable" >
            <el-option
                v-for="item in ignorableArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" :rows="2" v-model="ruleForm.content"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getUQIMGurl,getIMG} from "@/api/common"//图片上传接口
import * as axios from "@/api/editionApi"//本页面接口

export default {
  name: 'informat',
  data(){
    return{
      UpURL:'',
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      logo:'',
      keywords:'',
      ruleForm:{
        version_code:'',
        version_name:'',
        download_url:'',
        content:'',
        platform:0,
        target:0,
        is_ignorable:0,
      },
      platformArr:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      modeArr:[
        {
          id:0,
          name:'Android',
        },
        {
          id:1,
          name:'ios',
        },
      ],
      ignorableArr:[
        {
          id:0,
          name:'否',
        },
        {
          id:1,
          name:'是',
        },
      ],
      rules:{
        version_name: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        version_code: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        download_url: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        platform: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        is_ignorable: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        target: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        w_type:'all',
      }
        axios.getversionData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        version_code:'',
        version_name:'',
        download_url:'',
        content:'',
        platform:0,
        target:0,
        is_ignorable:0,
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.logo=row.logo
      this.logo=row.logo
      this.ruleForm.name=row.name
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddversion(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            // axios.posteditversion(this.ruleForm).then((res)=>{
            //   if(res.code==200){
            //     this.$message.success(res.msg)
            //     this.onTable()
            //     let that=this
            //     this.login=false
            //     setTimeout(function () {
            //       that.drawer=false
            //     },1000)
            //   }else {
            //     this.login=false
            //     this.$message.error(res.msg)
            //   }
            // }).catch((err)=>{
            //   this.login=false
            //   this.$message.error('服务器报错！！请联系管理员')
            // })
          }

        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
            axios.postdelversion({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
    handleAvatarSuccess(res, file) {
      this.logo = URL.createObjectURL(file.raw)
    },

    //上传图片方法
    httpRequest(options) {
      this.login=true
      console.log('options',options.file,options.file.type)
      return new Promise((resolve, reject) => {
        let src=options.file.type
      getUQIMGurl({bucket:'image',ext:'.'+src.substr(6)}).then((res)=>{
        console.log('获取URL',res)
        if(res.code==0){
          this.UpURL=res.data.upload_url
          this.ruleForm.logo=res.data.preview_url
          console.log(this.UpURL)
          getIMG(options.file,this.UpURL,options.file.type).then((res)=>{
            resolve(res)
            this.login=false
          }).catch((err)=>{
            reject(err)
            this.login=false
          })

        }else {
          this.$message.error('获取上传地址失败！！')
          this.login=false
        }
      })

      })
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'||
                    file.type === "image/png" ||
                    file.type === "image/jpg"||
                    file.type === "image/gif";
      // const isLt2M = file.size / 1024 / 1024 < 4
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG、 格式!')
      }
      // if (!isLt2M) {
        // this.$message.error('上传头像图片大小不能超过 4MB!')
      // }
      return isJPG
          // && isLt2M
    },
  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>