//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*广告接口---------------------------*/
//获取广告列表接口
export function getversionData(obj) {
    return request({
        url: '/api/admin/version',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增广告接口
export function postaddversion(obj,url) {
    return request({
        url: '/api/admin/version/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除广告接口
export function postdelversion(obj,url) {
    return request({
        url: '/api/admin/version/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


